import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/q-nHC-c0Cwo">
    <SEO title="A to Z Guide for Life - Greatest Hits" />
  </Layout>
)

export default SermonPost
